
import { Component, Vue } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { AxiosError } from 'axios';
import { Dictionary } from 'vue-router/types/router.d';

import LogoFia from '@/assets/img/LogoFia.svg';
import Loader from '@/components/shared/Loader.vue';
import { RouteName, PageAnchor } from '@/models';

const { LOGIN, INICIO } = RouteName;

@Component({
  name: 'Loading',
  components: {
    LogoFia,
    Loader,
  },
  methods: {
    ...mapActions('login', {
      loginWithToken: 'loginWithToken',
    }),
  },
})
export default class Loading extends Vue {
  private routeName: string;
  private query: Dictionary<string | (string | null)[]>;
  private hash: string;
  private token: string;

  private loginWithToken!: (token: string) => Promise<boolean>;

  created() {
    this.getRouterData();
  }

  private getRouterData() {
    const { params } = this.$route;
    this.routeName = RouteName[(params.routename as keyof typeof RouteName)];
    this.query = this.$route.query;

    if (this.query?.tokenAcesso) {
      this.token = this.query.tokenAcesso as string;
      delete this.query.tokenAcesso;
    }

    if (this.query?.hash) {
      this.hash = this.query.hash as string;
      delete this.query.hash;
    }
    this.loginWithTokenAcesso();
  }

  private async loginWithTokenAcesso() {
    if (!this.token) {
      this.routeName = LOGIN;
    }

    await this.loginWithToken(this.token)
      .catch((err: AxiosError) => {
        this.routeName = LOGIN;
        this.hash = '';
        this.query = {};
        console.error(err);
      })
      .finally(() => this.redirectTo());
  }

  private redirectTo() {
    if (!this.routeName) this.routeName = INICIO;

    this.$router.replace({
      name: this.routeName,
      hash: PageAnchor[this.hash as keyof typeof PageAnchor],
      params: { ...this.query as Dictionary<string> },
    });
  }
}
